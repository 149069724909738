import React, { useState, useEffect} from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TrackingSlider from "./Trackingslider";
import Button from '@mui/material/Button';
import RemoveIcon from '@mui/icons-material/Remove';
import {NavLink, useNavigate } from "react-router-dom";
import { Box, Grid, createTheme, useMediaQuery } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Container from '@mui/material/Container';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from "react-redux";
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import StarRatings from 'react-star-ratings';
import DeleteIcon from '@mui/icons-material/Delete';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import StarIcon from '@mui/icons-material/Star';

const Booked=()=>
{

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
      },
    },
  });

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const Itemed = styled(Paper)(({ theme }) => ({
    
    ...theme.typography.body2,
    padding: '20px',
    marginBottom:isSmScreen?"10px":"20px",
    borderRadius:'6px',
    
  
  }));

  const  customButton= {
    backgroundColor:'#4D1BDB',
    color:'var(--white)',
    width:'100%',
  fontSize:'16px',
  fontWeight: 500,
  textTransform:'none',
  opacity:'1',
  letterSpacing: '0.8px',
    fontFamily:'var(--fontfamily)',
    '&:hover': {
      backgroundColor: '#4D1BDB', 
    },
  
  };


  const deletebuttons=
  {
      color:'var(--primary)',
      textTransform:'none',
    }

    const StyledNavLink = styled(NavLink)(({ theme }) => ({
      color: 'inherit',
      fontSize:'12px',
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));
  
    const StyleNavLink = styled(NavLink)(({ theme }) => ({
      textDecoration: 'none',
      ariaCurrent:"page",
      fontSize:'12px',
      color:"var(--primary)",
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));

    const customDialogTitleStyle = {
    
      color: 'red',
      fontSize:'16px',
      fontFamily:'var(--fontfamily)',
      fontWeight:600
    };

    const modal={

      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    
  }
  
  const modalContent={
  
      width: '100%',
      maxWidth: 768,
  }

  const [openmodal, setopenmodal] = useState();

  const Baseurl=process.env.REACT_APP_BASE_URL;

  const node_base_url=process.env.REACT_APP_ORDER_CANCEL;

  const booking_number= localStorage.getItem('booking_no');
 

  const businessId=localStorage.getItem('storeId');
  const businessname=localStorage.getItem('businessname');

  const Logoitem=useSelector(state=>state.logo);

  const [isLoading, setIsLoading] = useState(true);
  const access= localStorage.getItem("accessToken");
  const [bookingdetails, setbookingdetails]=useState();
  const [show, setsshow]=useState(false);
  const [billing, setbilling] = useState();
  const [feedback, setfeedback]=useState([]);
  const [reviews, setreview]=useState();
  const [logisticsdata, setlogisticsdata]=useState([])


  let totalquantity=0;


  const [open1, setopen1]=useState(false)


  const [showtrack, setShowtrack]=useState(true)

  const AddressId=localStorage.getItem('addressId');

  const [userData, setuserData]=useState({
      cancel:""
  })


  
  const [errors, setErrors] = useState()

  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [openSnackbar1, setopenSnackbar1]=useState({
  
    openSnackbar: false,
    vertical: 'top',
    horizontal: 'center',
   });


   const { vertical, horizontal, openSnackbar } = openSnackbar1;

   const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar1({...openSnackbar1, openSnackbar:false});

  };
 
  const [openSnackbar2, setopenSnackbar2]=useState({

    opentoast: false,
    verticaltoast: 'top',
    horizontaltoast: 'center',
   });


   const { verticaltoast, horizontaltoast, opentoast } = openSnackbar2;

   const handleCloseToast = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setopenSnackbar2({...openSnackbar2, opentoast:false});

  };
  
   
  const navigate= useNavigate();

  const Cancel=()=>
  {
      isSmScreen ? setopen1(true): setopenmodal(true) 

  }

  const Closeicon=()=>
  {
     setopen1(false)
     setErrors({})
  }

  const Cancelmobile=()=>
  {
      setopenmodal(false);
      
  }

  const Keyboard=()=>
  {
      setShowtrack(!showtrack)
  }

  const handleClose1=()=>
  {
      setopen1(false);
  }  

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };


  const timerStarted = false;
   

  const handleChange = e => {
    e.preventDefault()

    const name = e.target.name
    const value = e.target.value

    const newErrors = {}

    if (name === 'cancel' && value.length === 0) {
      newErrors.cancel = 'Cancel reason is required'
    } else {
      newErrors.cancel = ''
    }
   
    if (
      newErrors.cancel === '' 
    ) {
      setErrors('')
    } else {
     
      setErrors(newErrors)
    }

    setuserData({ ...userData, [name]: value })
  }

  


    const Confirmmobile = async (newState) => {
      const newErrors = {};
    
      if (userData.cancel === "") {
        newErrors.cancel = 'Cancel reason is required';
      } else {
        newErrors.cancel = '';
      }
    
      if (newErrors.cancel === '') {
        try {
          
          const myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${access}`);
    
          const getRequestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
          };
    
          const getResponse = await fetch(`${Baseurl}/consumer/customer-order-cancel/${booking_number}/`, getRequestOptions);
          const getResult = await getResponse.text();
          const data = JSON.parse(getResult);
    
          if (data.status === 'success') {
            setopen1(false);
            setopenmodal(false);
            setErrors({});
            setuserData({ cancel: "" });
            setopenSnackbar1({ ...newState, openSnackbar: true });

            localStorage.setItem("awb_numbers",bookingdetails[0].order_data.co_order_transaction_id? bookingdetails[0].order_data.co_order_transaction_id:bookingdetails[0].order_data.co_transaction_id)

           if((bookingdetails[0].order_data.co_order_transaction_id && bookingdetails[0].order_data.co_order_transaction_id!=="") || bookingdetails[0].order_data.co_transaction_id!=="" )
           {
            try {
            
              const postHeaders = new Headers();
              postHeaders.append("Content-Type", "application/json");
        
              const raw = JSON.stringify({
                "data": {
                  "access_token": logisticsdata[0].access_token,
                  "secret_key": logisticsdata[0].secret_key,
                  "awb_numbers": bookingdetails[0].order_data.co_order_transaction_id? bookingdetails[0].order_data.co_order_transaction_id:bookingdetails[0].order_data.co_transaction_id,
                }
              });
        
              const postOptions = {
                method: "POST",
                headers: postHeaders,
                body: raw,
              };
        
              
              const postResponse = await fetch(`https://my.ithinklogistics.com/api_v3/order/cancel.json`, postOptions);
              const postResult = await postResponse.json();
        
            } catch (error) {
          
              console.error('Error in POST request:', error);
            }
            }

              setTimeout(() => {
                navigate(`/${businessname}/order-history`);
              }, 1200); 
            

          } else {
            throw new Error('Cancellation failed');
          }
        } catch (error) {
          console.error('Error:', error);
          return 404;
        }
      } else {
        setErrors(newErrors);
      }
    };
    

  useEffect(()=>
  {
    const apiUrl13=`${Baseurl}/consumer/customer-order-tracking/${booking_number}/`;
    const apiUrl14=`${node_base_url}/shipping/${businessId}/`

    const fetchData = async()=>
    {
        try 
        {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${access}`);
            
            const formdata = new FormData();
            formdata.append("business_owner_id", businessId);
            
            const requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: formdata,
              redirect: "follow"
            };
            
               const response1 = await fetch(apiUrl13, requestOptions);
                const Update = await response1.json(); 

                 console.log(Update);
                
                 setbookingdetails([Update]);
                 setbilling(Update.billing_details);
                 setfeedback(Update.product_details)
                console.log(Update.product_details)
                 setreview(Update.review)

                 localStorage.removeItem("reviewId")
          

                 const response2 = await fetch(apiUrl14);
                 const Logistics = await response2.json(); 
           
                 setlogisticsdata(Logistics)

                setIsLoading(false)
        }
        catch (error) {
          console.log(error);
          setIsLoading(false);
        }
    };

    if (isLoading) {
      fetchData();
    }
      
  },[isLoading])

  useEffect(()=>
  {
    if(bookingdetails)
    {
    if((bookingdetails[0].booking_status===4 || bookingdetails[0].booking_status===7 || bookingdetails[0].booking_status===8))
    {
      setsshow(true)
    }
    else
    {
      setsshow(false)
    }
  }

  },[bookingdetails])
 
  const Feedback=(productId, reviewId)=>
  {
      localStorage.setItem("reviewId", reviewId)
      localStorage.setItem("productId", productId)
      navigate(`/${businessname}/customer-review`)
  }

  const Rating=(productId)=>
  {
    localStorage.setItem("productId", productId)
    navigate(`/${businessname}/customer-review`)
  }

const Returnorder=()=>
{

   
   navigate(`/${businessname}/return`)
}


    const DeleteReview=(Id, newState)=>
    {

      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${access}`);

      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      
      fetch(`${Baseurl}/consumer/customer-delete-review-web/${Id}/?business_owner_id=${businessId}`, requestOptions)
        .then(response => response.text())
        .then(result => {

          const data =JSON.parse(result)

          if(data.status==='Success')
          {
            localStorage.removeItem("reviewId")
            setopenSnackbar2({ ...newState, opentoast: true });
            setIsLoading(true)
        }
      })
        .catch(error => console.log('error', error));
    }

    const handledownload=()=>
    {
      const invoiceUrl = bookingdetails[0].booking_invoice;
  
      const anchor = document.createElement('a');

      anchor.href = invoiceUrl;
      anchor.download = `${bookingdetails[0].booking_details.order_id}.pdf`
    
      anchor.click();
    }

    function formatIndianNumber(number) {
      const parts = number.toString().split(".");
      let integerPart = parts[0];
      let formattedInteger = "";
    
      while (integerPart.length > 3 && number>=10000) {
        formattedInteger = "," + integerPart.slice(-3) + formattedInteger;
        integerPart = integerPart.slice(0, -3);
      }
      formattedInteger = integerPart + formattedInteger;
    
      return parts.length > 1 ? formattedInteger + "." + parts[1] : formattedInteger;
    }


    return (
        <>
             {bookingdetails!==undefined &&
          
          <section className= {isSmScreen? "m-10": "section1 p-10"}>
            
          <Container maxWidth='lg' className="p-16">

            <div className="breadcrumb mobile-bodypadding">
                <Breadcrumbs aria-label="breadcrumb">
            <StyledNavLink to={`/${businessname}/`}>
              Home
            </StyledNavLink>
            <StyledNavLink   to={`/${businessname}/order-history`}  >
             My Order
            </StyledNavLink>
            <StyleNavLink
              to={`/${businessname}/bookingId/${bookingdetails[0].order_data._id}`}  
            >
              {bookingdetails[0].order_data._id}
            </StyleNavLink>
            
          </Breadcrumbs>
      </div>
              
            <Box sx={{width:"100%"}}>

            <Grid container rowSpacing={2}   columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="center"  >

         <Grid item xs={12} sm={isSmScreen ? 12 : 7} md={7}>

         <Itemed className="global_box_shadow mobile-bodymargin">
              {bookingdetails.map(cur=>
                {
                   return (
                    <>
                        {(() => {
                    const itemCount = cur.product_details.length;
                      return (
                    <div>
                    <div className="booking_time" style={{paddingBottom:'8px'}}>{itemCount} {itemCount === 1 ? 'ITEM' : 'ITEMS'}</div>

                            </div>
                      );
                    })()}
                               <div className="product_details">
        {               
                  cur.product_details.map((cur,index)=>
                    {
                       totalquantity+=cur.co_quantity
                     

                       return (
                        <>
                              <div key={index} className="order_items">
                            <div style={{display:'flex',gridColumnGap:'20px'}}>
                            <div className="product_img"><LazyLoadImage src={cur.product_info[0].bo_product_images.img_urls[0]} alt='product image'/></div> 

                              <div style={{width:'100%'}}>
                              {(cur.product_info.length!==0 && cur.product_info[0].varient_info.length!==0) ? 
                             <span className="address_detail">
                                        {cur.product_info[0].bo_product_name}<div className="variantDetails" style={cur.product_info[0].varient_info[0] && {paddingBottom:'4px'}}>
                                        [{cur.product_info[0].varient_info[0] && cur.product_info[0].varient_info[0].bo_product_variant_value}{cur.product_info[0].varient_info.length>1 && " + " }{cur.product_info[0].varient_info[0].length>1 && cur.product_info[0].varient_info[1].bo_product_variant_value}]</div>        
                                        </span>
                              :
                            <span className="address_detail">
                            {cur.product_info[0].bo_product_name}              
                            </span>

                       } 

                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                <div className="address_detail"><span>{cur.co_quantity}</span> x 
                       {cur.product_info.length!==0 && cur.product_info[0].varient_info.length!==0 ? 
                    <span>
                  
                  {cur.product_info[0].varient_info.length>1 ? <span> ₹{formatIndianNumber(cur.product_info[0].varient_info[1].bo_product_discount)}</span>:<span> ₹{formatIndianNumber(cur.product_info[0].varient_info[0].bo_product_discount)}</span>}
                  
                  </span>
                :
                 <span> ₹{formatIndianNumber(cur.product_info[0].bo_product_discounted_price)}</span>
               }
                       
                                </div>
                                {cur.product_info.length!==0 && cur.product_info[0].varient_info.length!==0 ? 
                    <span>
                  
                  {cur.product_info[0].varient_info.length>1 ?  <div className="totalprices">
                                  
                                  ₹{formatIndianNumber(parseInt(cur.co_quantity*cur.product_info[0].varient_info[1].bo_product_discount))}
                                  </div>
                                  :
                                   <div className="totalprices">
                                  
                                   ₹{formatIndianNumber(parseInt(cur.co_quantity*cur.product_info[0].varient_info[0].bo_product_discount))}
                                   </div>
                                   }
                  
                  </span>
                :
                <div className="totalprices">
                                  
                ₹{formatIndianNumber(parseInt(cur.co_quantity*cur.product_info[0].bo_product_discounted_price))}
                </div>
               }
                                
                                </div>
                              </div>
                              </div>
                            </div>

                        </>
                       )    
                    })
                  }
                  </div>

                    </>
                   )
                })}

           
             </Itemed>


           <Itemed className="global_box_shadow mobile-bodymargin">
           <div className="tracking_service  service-tracker">
          
           <h1 className="explore_title ">Order Activity</h1>
         {showtrack===true ?  <div className="keyboardup" onClick={Keyboard}><KeyboardArrowUpIcon/></div>:
           <div className="keyboardup" onClick={Keyboard}><KeyboardArrowDownIcon/></div>}

           </div>
             
                        
        {showtrack &&   <div>
           <TrackingSlider  bookingdetails={bookingdetails} />  
            
            </div>
        }
           
            </Itemed>

            {(bookingdetails[0].order_data.co_order_status===4 || bookingdetails[0].order_data.co_order_status===7 || bookingdetails[0].co_order_status===8) && <Itemed className="global_box_shadow mobile-bodymargin">
              <div className="feedback">
              <h1 className="feed-back" style={{ margin: "0px" }}>Your Review</h1>
              
               
              {feedback.length!==0 && feedback.map(cur => {
  
                    let foundMatchingReview = false;

                    return (
                      <div className="feedback_data" key={cur._id}>
                        <div>
                          {cur.product_info[0].varient_info.length !== 0 ? (
                            <h2 className="booking_name" style={{ margin: "0px",fontWeight:500 }}>
                              {cur.product_info[0].bo_product_name}<div className="variantDetails" style={cur.product_info[0].varient_info.length && {paddingBottom:'4px'}}>[{cur.product_info[0].varient_info && cur.product_info[0].varient_info[0] && cur.product_info[0].varient_info[0].bo_product_variant_value}
                             {cur.product_info[0].varient_info.length > 1 && " + "}{cur.product_info[0].varient_info.length > 1 && cur.product_info[0].varient_info[1].bo_product_variant_value}]</div> 
                            </h2>
                          ) : (
                            <h2 className="booking_name" style={{ margin: "0px",fontWeight:500 }}>
                              {cur.product_info[0].bo_product_name}
                            </h2>
                          )}
                        </div>

                        {reviews.map(review => {
                        
                          if (!foundMatchingReview && review.bo_product_id === cur.bo_product_id) {
                            // const reviewImagesString = review.c_review_images.replace(/'/g, '"');
                            const reviewImages =[] //JSON.parse(reviewImagesString);
                            foundMatchingReview = true;

                            return (
                              <div key={review._id}>
                                <div onClick={() => Feedback(review.bo_product_id, review._id)}>
                                <div className="overall_starrating" style={{width:'fit-content', marginTop:"4px"}} >
                                  <div>{review.c_rating}</div>
                                  <StarIcon  className="starvalues"/>
                              </div>

                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div className="booking_time">{review.c_review_message}</div>
                                    <div onClick={(e) => { e.stopPropagation(); DeleteReview(review._id, { vertical: 'top', horizontal: 'center' }) }} style={{ transform: "scale(0.8)", display: 'flex', alignItems: 'center' }}><DeleteIcon /></div>
                                  </div>

                                  <div className="image-uploader">
                                    {reviewImages.map(imageUrl => (
                                      <div key={imageUrl} className="image_lazyloads">
                                        <LazyLoadImage src={imageUrl} alt="category" className="checkoutimage" />
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            );
                          }

                          return null; 
                        })}

                        {!foundMatchingReview && (
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={() => Rating(cur.bo_product_id)}>
                            <NavLink className="review_rating">Add your rating</NavLink>
                            <NavLink className="review_rating"><KeyboardArrowRightIcon /></NavLink>
                          </div>
                        )}
                      </div>
                    );
      })}

              </div>
             


            </Itemed>
}

            
            </Grid>

           
           
            <Grid item xs={12} sm={isSmScreen ? 0 : 5} md={5} className="mmt-0">
            
            <Itemed className="global_box_shadow mobile-bodymargin">
           
           <div className="item_booking">
              <div className="totalcost">
            <div className="explore_title ">Billing Details</div>
           {bookingdetails[0]?.booking_invoice!=="" &&   <NavLink onClick={handledownload}><span style={{fontSize:'14px'}}> invoice</span></NavLink>}
            </div>
           
             <div className="totalcost">
              <div className="address_detail">Item Price {totalquantity > 1 ? `(${totalquantity} items)` : `(${totalquantity} item)`}</div>
              <div className="totalprices">₹{formatIndianNumber(billing.product_bill)}</div>
            

             </div>

           

             <div className="totalcost">
              <div className="address_detail">Discount</div>
              
             <div className="total_discount"><RemoveIcon className="remove_discount"/>₹{formatIndianNumber(billing.discount)}</div>
               
             </div>

          <div className="border"></div>              
   
             <div className="totalcost">
             <div className="total_money"> Grand Total {totalquantity > 1 ? `(${totalquantity} items)` : `(${totalquantity} item)`} </div>
              <div className="totalprices">₹{formatIndianNumber(billing.total)}</div>
            
             </div>
             </div>
         
          </Itemed>

            
            <Itemed className="global_box_shadow mobile-bodymargin">

            <div className="item_booking">
             
            <div className="totalcost">
             <div className="explore_title ">Delivery Address</div>
            </div>

              <div className="totalcost">

               <div className="address_detail">Payment</div>
               <div className="booking_time" >{bookingdetails[0].order_data.co_payment_type}</div>

              </div>

              <div className="totalcost">
               
               <div style={{fontFamily:'var(--fontfamily)', fontWeight:500}}>{JSON.parse((bookingdetails[0]?.order_data.co_address).replace(/'/g, '"').replace(/\"(\w+)\":/g, '"$1":'))[0].address}</div>
                
              </div>
              <div className="totalcost">
               <div className="address_detail">Phone number</div>
               <div className="booking_time">{bookingdetails[0].order_data.c_mobile_no}</div>
                  
              </div>
               
              </div>
                  
           </Itemed>
          
          
         {(bookingdetails[0].order_data.co_order_status===4) ? 
           <Itemed className="global_box_shadow mobile-bodymargin"> <Button style={customButton} className='secondary_button' onClick={Returnorder}>Return</Button> </Itemed>
           :  
           (isSmScreen ?(open1===false && bookingdetails[0].order_data.co_order_status<4) && <Itemed className="global_box_shadow mobile-bodymargin"><Button style={customButton} className='secondary_button' onClick={Cancel}>Cancel Order</Button> </Itemed> :bookingdetails[0].order_data.co_order_status<4 && <Itemed className="global_box_shadow mobile-bodymargin"> <Button style={customButton} className='secondary_button' onClick={Cancel}>Cancel Order</Button></Itemed> )}
           

            </Grid>
          
            
            </Grid>
            </Box>

            <Stack spacing={2} sx={{ width: '100%' }}>
  
            <Snackbar open={openSnackbar} autoHideDuration={2000}  key={vertical + horizontal}  anchorOrigin={{ vertical, horizontal }} onClose={handleClose}  >
            <Alert  severity="error" sx={{ width: '100%' }}>
                  Your order is successfully cancel!.
                  </Alert>
              </Snackbar>

            </Stack>

           
            <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={opentoast} autoHideDuration={2000}  key={verticaltoast + horizontaltoast}  anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast}  >
                     <Alert  severity="success" sx={{ width: '100%' }}>
                         Review delete successfully!.
                           </Alert>
                      </Snackbar>

                    </Stack>
           
            {!isSmScreen ?    <Dialog
       
        open={openmodal}
        aria-labelledby="responsive-dialog-title"
      >
       <DialogTitle id="responsive-dialog-title" style={customDialogTitleStyle}>
         {"Cancel Order"}
       </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 0, width: '45ch' },
      }}
      autoComplete="off"
    >
          <TextField
               fullWidth
               id="standard"
          label={
            <span>
               Enter reason <span style={{ color: 'red' }}>*</span>
            </span>
          }
          name='cancel'
          value={userData.cancel}
          error={Boolean(errors?.cancel)}
          helperText={errors?.cancel}
          onChange={handleChange}
          variant="standard"
        />
        </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus style={deletebuttons} onClick={Cancelmobile}>Cancel</Button>
          <Button style={deletebuttons} onClick={()=>Confirmmobile({ vertical: 'top', horizontal: 'center' })}>Yes, Confirm</Button>
        </DialogActions>
      </Dialog>
   :

      <Modal    open={open1}   onClose={handleClose1} style={modal} aria-labelledby="modal-title">
        <div style={modalContent}>
        <div className='modalitems'>
          <div style={{display:'flex', justifyContent:'space-between',alignItems:'center'}}>
          <div style={customDialogTitleStyle}>Cancel Order</div>
          <div onClick={Closeicon}> <CloseIcon/></div>

         </div>
        <Box
      component="form"
      sx={{
        '& > :not(style)': { mb: 1.5, width: '100%' },
      }}
      autoComplete="off"
    >
          <TextField
               fullWidth
               id="standard"
          label={
            <span>
               Enter reason <span style={{ color: 'red' }}>*</span>
            </span>
          }
          name='cancel'
          value={userData.cancel}
          error={Boolean(errors?.cancel)}
          helperText={errors?.cancel}
          onChange={handleChange}
          variant="standard"
        />
        
       <Button  style={customButton} className='secondary_button' onClick={()=>Confirmmobile({ vertical: 'top', horizontal: 'center' })}>Cancel</Button>
       
        </Box>
          </div>
          </div>
          </Modal>
}
           </Container>
          </section>
}
        </>
    )
  
}

export default Booked;


/* 

  <div className="totalcost">
              <div className="address_detail">Delivery fee</div>
              <div className="total_discount">FREE</div>
                 
             </div>

console.log('booking1')
   
      
      */
      