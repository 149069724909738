import React,{useState, useEffect} from "react";
import { Box, Grid, createTheme, useMediaQuery, TextareaAutosize , Typography} from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {ReactComponent as Addlogo} from "../assests/Photo.svg";
import { useNavigate, NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import Container from '@mui/material/Container';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Breadcrumbs from '@mui/material/Breadcrumbs';

const Item = styled(Paper)(({ theme }) => ({
    
   ...theme.typography.body2,
   borderRadius:'6px',
   marginBottom:"20px",
   padding:'20px',

 }));

 const Items = styled(Paper)(({ theme }) => ({
    
   ...theme.typography.body2,
   padding:'20px',
   marginBottom:"20px",
   borderRadius:'6px',


 }));
 
 function getCurrentDateTime() {
   const options = {
     day: 'numeric',
     month: 'short',
     year: 'numeric',
     hour: 'numeric',
     minute: '2-digit',
     hour12: true,
   };
 
   const currentDateTime = new Date().toLocaleString('en-US', options);
   const formattedDateTime = currentDateTime.replace(',', '');
   return formattedDateTime;
 }


const Return=()=>
{
     const [showbutton, setshowbutton]=useState(true);

    

    const  customButtons= {
      backgroundColor:'#57339F',
      color: '#ffffff',
      width:'100%',
    fontSize:'16px',
    border:'2px solid var(--primary)',
    opacity:'0.7',
    letterSpacing: '0.8px',  
      '&:hover': {
          backgroundColor:'#57339F',
      },
    
    }; 
    
    const custom=
    {
      backgroundColor:'#57339F',
      color: '#ffffff',
      width:'100%',
      textTransform:'none',
    fontSize:'16px',
    border:'2px solid var(--primary)',
    outline:'none',
    opacity:showbutton?'0.7':'1', 
      '&:hover': {
          backgroundColor:'#57339F',
      },

    }

    const StyledNavLink = styled(NavLink)(({ theme }) => ({
      color: 'inherit',
      fontSize:'12px',
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));
  
    const StyleNavLink = styled(NavLink)(({ theme }) => ({
      textDecoration: 'none',
      ariaCurrent:"page",
      fontSize:'12px',
      color:"var(--primary)",
      fontWeight:'500',
      fontFamily:'var(--fontfamily)',
      '&:hover': {
        textDecoration: 'underline',
      },
    }));

    const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
        },
      },
    });

    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    const booking_number= localStorage.getItem('booking_no');
    
    const node_base_url=process.env.REACT_APP_ORDER_CANCEL;

    const [isLoading, setIsLoading] = useState(true);
    const Baseurl=process.env.REACT_APP_BASE_URL;
    const [service, setservice]=useState();

    const [user, setuser] = useState()

    const [text, settext]=useState('')
  
    const access= localStorage.getItem("accessToken");
    const businessId=localStorage.getItem('storeId');
    const businessname=localStorage.getItem('businessname');

    const awb_numbers=localStorage.getItem('awb_numbers');

    const [logisticsdata, setlogisticsdata]=useState([])

    const [starColor1, setStarColor1] = useState('lightgray');


   const [customstyle, setcustomstyle]= useState(customButtons);

    const [rating1, setRating1] = useState(0);
   
    
    const timerStarted = false;

    const navigate=useNavigate();

    const [file, setfile]=useState([]);
    const [name, setname]=useState([]);
   

    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
  
    const [userData, setuserData]=useState({
        upiid:""
    })
  
    const [userBank, setuserBank]=useState({
        bank_account:"",
        bank_name:"",
        bank_ifsc:""
        
    })
  
    
    const [errors, setErrors] = useState()

      
    const [errors1, setErrors1] = useState()
    
  
     const [openSnackbar1, setopenSnackbar1]=useState({
  
      openSnackbar: false,
      vertical: 'top',
      horizontal: 'center',
     });
  
  
     const { vertical, horizontal, openSnackbar } = openSnackbar1;
  
     const handleClose = (reason) => {
      if (reason === 'clickaway') {
        return;
      }
        navigate(-1);
      setopenSnackbar1({...openSnackbar1, openSnackbar:false});
  
    };

    const [openSnackbar2, setopenSnackbar2]=useState({

      opentoast: false,
      verticaltoast: 'top',
      horizontaltoast: 'center',
     });
  
  
     const { verticaltoast, horizontaltoast, opentoast } = openSnackbar2;
  
     const handleCloseToast = (reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setopenSnackbar2({...openSnackbar2, opentoast:false});
  
    };
  
     
    const handlepaymentupi = e => {
        e.preventDefault()
    
        const name = e.target.name
        const value = e.target.value
    
        const newErrors = {}
        

        if (name === 'upiid' && value.length === 0) {
          newErrors.upiid = 'Upi id is required.'
         } 
        else {
          newErrors.upiid = ''
        }
       
        if (
          newErrors.upiid === '' 
        ) {
          setErrors(undefined)
        } else {
         
          setErrors(newErrors)
        }
    
        setuserData({ ...userData, [name]: value })
      }
  
      const handlepaymentbank = e => {
        e.preventDefault()
    
        const name = e.target.name
        const value = e.target.value
    
        const newErrors = {}
    
        if (name === 'bank_account' && value.length === 0) {
          newErrors.bank_account = 'Bank account number is required'
        } else {
          newErrors.bank_account = ''
        }
        if (name === 'bank_ifsc' && value.length === 0) {
            newErrors.bank_ifsc = 'Bank IFSC code is required'
          } else {
            newErrors.bank_ifsc = ''
          }
          if (name === 'bank_name' && value.length === 0) {
            newErrors.bank_name = 'Bank name is required'
          } else {
            newErrors.bank_name = ''
          }
       
        if (
          newErrors.bank_account === '' && 
          newErrors.bank_ifsc === '' &&
          newErrors.bank_name === ''
        ) {
          setErrors1(undefined)
        } else {
         
          setErrors1(newErrors)
        }
    
        setuserBank({ ...userBank, [name]: value })
      }
  


    const handleFileChange = (e,newState) => {
   

      const files = e.target.files;

      const selectedFiles = [...file, ...files].slice(0, 5);
      setfile(selectedFiles);
      setname(selectedFiles.name);
     
     

      if (file.length + files.length > 5) {

        setopenSnackbar2({ ...newState, opentoast: true });
      }
 
      const fileInput = e.target.files;
     
    };

    const handleChange=(e)=>
    {
       
        const value = e.target.value;
        settext(value);
    }

    const handleChangepayment=(e)=>
    {
        const value = e.target.value;
        setuser(value);
        if(value==='Bank')
        {
            setErrors({})
            setuserData({upiid:""})
        }
       if(value==='upi')
       {
        setErrors1({})
        setuserBank({bank_account:"",bank_ifsc:"",bank_name:""})
       }
      
    }

   

    useEffect(()=>
    {
     
      const apiUrl17=`${Baseurl}/consumer/customer-order-tracking/${booking_number}/`;
      const apiUrl14=`${node_base_url}/shipping/${businessId}/`

      const fetchData = async()=>
      {
          try 
          {
       
              
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${access}`);
            
            const formdata = new FormData();
            formdata.append("business_owner_id", businessId);
            
            const requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: formdata,
              redirect: "follow"
            };
            
               const response1 = await fetch(apiUrl17, requestOptions);
                  const Update = await response1.json(); 
                   console.log([Update]);
                  
                   setservice([Update])


                 const response2 = await fetch(apiUrl14);
                 const Logistics = await response2.json(); 
           
                 setlogisticsdata(Logistics)
                  
                  setIsLoading(false)
          }
          catch (error) {
            console.log(error);
            setIsLoading(false);
          }
      };
  
      if (isLoading) {
        fetchData();
      }
        
    },[isLoading])
       
        
  
  
   console.log("service",service)
   console.log(file);
   console.log(name)

  const Submit = (newState)=>
  {

    const newErrors = {}
 

    if (userData.upiid === "") {
      newErrors.upiid = 'Upi id is required'
    } 
     else
    {
      newErrors.upiid = ''
    }

    if(userBank.bank_account==="")
    {
        newErrors.bank_account = 'Bank account number is required'
    } else {
      newErrors.bank_account = ''
    }
    if(userBank.bank_ifsc==="")
    {
        newErrors.bank_ifsc = 'Bank ifsc code is required'
    } else {
      newErrors.bank_ifsc = ''
    }
    if(userBank.bank_name==="")
    {
        newErrors.bank_name = 'Bank name is required'
    } else {
      newErrors.bank_name = ''
    }
    

    if (
      newErrors.upiid === '' || (newErrors.bank_account==='' && 
      newErrors.bank_ifsc === '' && newErrors.bank_name===''
      )
    )
    {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${access}` ); 
    
        let formdata = new FormData();
        formdata.append("order_number", booking_number);
        formdata.append("business_owner_id", businessId);
    
        {file?.map(cur=>
          {
              formdata.append("product_image", cur, cur.name);
          })}
    
          formdata.append("return_reason", text);
          formdata.append("return_pay_type", user);
          formdata.append("return_bank_account_number", userBank.bank_account);
          formdata.append("return_bank_name", userBank.bank_name);
          formdata.append("return_bank_ifsc_code", userBank.bank_ifsc);
          formdata.append("return_upi_id", userData.upiid);
         
        
        
        let requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow'
        };
       
       
                
        fetch(`${Baseurl}/consumer/customer-order-return/`, requestOptions)
        .then(response => response.text())
        .then(result=>{
            const data= JSON.parse(result);
            
            if(data.status==='success')
            {
                setIsLoading(false);
              setErrors({})
              setuserData({upiid:""})
              setErrors1({})
              setuserBank({bank_account:"",bank_ifsc:"",bank_name:""})
              setopenSnackbar1({ ...newState, openSnackbar: true })

              const postHeaders = new Headers();
              postHeaders.append("Content-Type", "application/json");
        
              const raw = JSON.stringify({
                "data": {
                  "access_token": logisticsdata[0].access_token,
                  "secret_key": logisticsdata[0].secret_key,
                  "awb_numbers":awb_numbers,
                }
              });
        
              const postOptions = {
                method: "POST",
                headers: postHeaders,
                body: raw,
              };
        
              
              const postResponse = fetch(`https://my.ithinklogistics.com/api_v3/order/cancel.json`, postOptions);
              const postResult = postResponse.json();

              
              if (!timerStarted) {

                const timer2 = setTimeout(() => {
                  
                  navigate(`/${businessname}/order-history`)
                  
                }, 1200);
                return () => clearTimeout(timer2);
                     
            }
             
            }
    
        })
        .catch(error => console.log('error', error));
    }

    else 
    {
      setErrors(newErrors)
      setErrors1(newErrors)
    }

}
    



   const handleRemoveImage = (index) => {
    const updatedImages = file.filter((_, i) => i !== index);
    setfile(updatedImages);
  };


  useEffect(() => {
    if (user!==''  && file.length!==0 && text!=='' && (errors!==undefined || errors1!==undefined)) {
      setshowbutton(false);
    } else {
      setshowbutton(true);
    }
  }, [starColor1,file,text,errors,errors1]);

 

     return (
        <>
 
       {service!==undefined &&
        
                 <section className= {isSmScreen? "slider-header service-margin": "section1 explore-padding mt-20"}>
                <Container maxWidth='lg' className="p-16">
            
                       
       <div className="breadcrumb mobile-bodypadding">
            <Breadcrumbs aria-label="breadcrumb">
        <StyledNavLink to={`/${businessname}/`}>
          Home
        </StyledNavLink>
        <StyledNavLink to={`/${businessname}/order-history`} >
          My Order
        </StyledNavLink>
        <StyledNavLink
              to={`/${businessname}/bookingId/${service[0].order_data._id}`}  
            >
              {service[0].order_data._id}
            </StyledNavLink>
        <StyleNavLink
          to={`/${businessname}/return`}  
        >
          Return
        </StyleNavLink>
        
      </Breadcrumbs>
      </div>
            
                       <Box sx={{width:"100%"}}>
                       <Grid container rowSpacing={0}   columnSpacing={{xs:2, sm:3, md:4}} display="flex" justifyContent="center"  >

                        <Grid item xs={12} sm={isSmScreen ? 12 : 7} md={7} >

                       <Items  className="global_box_shadow">
                    
                       <div>
                       <div className="ratings-data">
                        <div className="customer-said customer-review">Upload Image</div>
                         <div>
                           
                            <div className="add-photo">

                        
                              {file.length===0 ? <label htmlFor='fileinput'><Addlogo/></label> :
                              <div className="image-uploader">
                              {file?.map((image, index)=>{

                                  return (
                                    <>
                                      <div key={index} className="image_container" >
                                      <label htmlFor='fileinput'><div className="image_lazyloads"><LazyLoadImage  src={URL.createObjectURL(image)}  alt="category" className="checkoutimage" /></div></label>
                                       <div className="delete-image" onClick={() => handleRemoveImage(index)}><HighlightOffTwoToneIcon  style={{color:"black", transform:'scale(0.7)'}}/></div>
                                       </div>
                                    </>
                                  )
                              })}
                              </div>
                            }
                        
                        
                            <input type="file"  id="fileinput" style={{display:"none"}} multiple onChange={(e)=>handleFileChange(e, { vertical: 'top', horizontal: 'center' })}/>
                          

                            </div>

                         </div>
                        </div>
                        </div>
                            
                       </Items>
                     
                       <Items  className="global_box_shadow">
                       
                       <div>
                       <div className="ratings-data">
                        <div className="customer-said customer-review">Reason</div>
                         <div>
                          
                           <TextareaAutosize minRows={5} className="textareaautosize" value={text} placeholder="Enter your reason"  onChange={handleChange} ></TextareaAutosize>
                         </div>
                        </div>
                        </div>

                       </Items>

                       <Items  className="global_box_shadow">
                       
                       <div>
                       <div className="ratings-data">
                        <div className="customer-said customer-review">Pick Up Address</div>
                         <div>
                          
                         <div style={{fontFamily:'var(--fontfamily)', fontWeight:'500'}}> {JSON.parse((service[0]?.order_data.co_address).replace(/'/g, '"').replace(/\"(\w+)\":/g, '"$1":'))[0].address}{" "}
                         {service[0].order_data.co_city}{" "}
                         {service[0].order_data.co_state}{" "}
                         {service[0].order_data.co_zipcode}
                         
                         </div>
                         <div style={{fontFamily:'var(--fontfamily)', fontWeight:'500'}}>Contact number: {service[0].order_data.c_mobile_no} </div>
                         </div>
                        </div>
                        </div>

                       </Items>
                       </Grid>

                    <Grid item xs={12}sm={isSmScreen ? 12 : 5} md={5} >

                  

                       <Items className="global_box_shadow">

                       <div>
                       <div className="ratings-data">
                        <div className="customer-said customer-review">Refund Type</div>
                         <div>
                          
                         <RadioGroup name="paymentMethod" value={user} onChange={handleChangepayment}>
                         <FormControlLabel value="upi" checked={user === 'upi'} control={<Radio />} label={<Typography>UPI ID</Typography>} />
                      {user==='upi' &&   <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { mb: 1, width: '100%' },
                                pl:4,pr:4
                            }}
                            autoComplete="off"
                            >
                                <TextField
                                    fullWidth
                                    id="standard"
                                    placeholder="UPI ID"
                                name='upiid'
                                value={userData.upiid}
                                error={Boolean(errors?.upiid)}
                                helperText={errors?.upiid}
                                onChange={handlepaymentupi}
                                variant="standard"
                                />
                                </Box>}
                         <FormControlLabel value="Bank" checked={user === 'Bank'} control={<Radio />} label={<Typography>Bank</Typography>} />
                             
                       {user==='Bank' &&  <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { mb: 1, width: '100%' },
                                pl:4,pr:4
                            }}
                            autoComplete="off"
                            >
                                <TextField
                                    fullWidth
                                    id="standard"
                                    type="number"
                                    placeholder="Bank account number"
                                name='bank_account'
                                value={userBank.bank_account}
                                error={Boolean(errors1?.bank_account)}
                                helperText={errors1?.bank_account}
                                onChange={handlepaymentbank}
                                variant="standard"
                                />
                                 <TextField
                                    fullWidth
                                    id="standard"
                                    placeholder="Bank ifsc code"
                                name='bank_ifsc'
                                value={userBank.bank_ifsc}
                                error={Boolean(errors1?.bank_ifsc)}
                                helperText={errors1?.bank_ifsc}
                                onChange={handlepaymentbank}
                                variant="standard"
                                />
                                 <TextField
                                    fullWidth
                                    id="standard"
                                    placeholder="Bank name"
                                name='bank_name'
                                value={userBank.bank_name}
                                error={Boolean(errors1?.bank_name)}
                                helperText={errors1?.bank_name}
                                onChange={handlepaymentbank}
                                variant="standard"
                                />
                                </Box>}

                        </RadioGroup>
                         </div>
                        </div>
                        </div>
                       
                     {isSmScreen ? "" :  <div className="addressdatebutton experience-service"><Button style={custom} className="primary_button" onClick={()=>Submit({ vertical: 'top', horizontal: 'center' })} disabled={showbutton} >Submit</Button> </div>}  

                       </Items>

                       
                        {isSmScreen ?  <Items> <Button style={custom} className="primary_button" onClick={()=>Submit({ vertical: 'top', horizontal: 'center' })} disabled={showbutton} >Submit</Button>  </Items> : ""}  

                       
                         
                           </Grid>
                     </Grid>
                     </Box>
                     <Stack spacing={2} sx={{ width: '100%' }}>
  
                    <Snackbar open={openSnackbar} autoHideDuration={2000}  key={vertical + horizontal}  anchorOrigin={{ vertical, horizontal }} onClose={handleClose}  >
                     <Alert  severity="success" sx={{ width: '100%' }}>
                         Order return successfully!
                           </Alert>
                      </Snackbar>

                    </Stack>

                       <Stack spacing={2} sx={{ width: '100%' }}>
  
                    <Snackbar open={opentoast} autoHideDuration={2000}  key={verticaltoast + horizontaltoast}  anchorOrigin={{ vertical, horizontal }} onClose={handleCloseToast}  >
                     <Alert  severity="error" sx={{ width: '100%' }}>
                         Maximum 5 images upload
                           </Alert>
                      </Snackbar>

                    </Stack>
                   
                  </Container>
          </section>
}           
        </>
     )

}

export default Return;

/* 

   {(name===''|| name===undefined)? <Addlogo/> : <div className="image_lazyload"><LazyLoadImage  src={Image}  alt="category" className="checkoutimage" /></div>} 


 formdata.append("booking_number", booking_number);
   formdata.append("service_id", service[0].service_id);
    formdata.append("overall_services", rating1);
    formdata.append("staff_behaviour", rating2);
    formdata.append("services_on_time", rating3);
    formdata.append("services_by_staffs", rating4);
    formdata.append("review_message", text);
    formdata.append("images", file, name);



 <div className="mylocations">

                          <div><CloseIcon className="close-icon"/></div>
                           </div>

 

<div className="mylocations">
                         <div className="customer-said customer-review"> Services on time</div>
                        <div><CloseIcon className="close-icon" /></div> 
                         </div>


                       */